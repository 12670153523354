import React, {useState} from 'react';
import _ from '../../utils/_';

import Plus from '../icons-af/plus';
import Minus from '../icons-af/minus';
import {H5} from '../typography/typography';

const FilterSection = ({
  items,
  selected,
  type,
  label,
  id = _.prop('id'),
  name = _.prop('name'),
  value = (_, index) => index,
  onChange,
}: propTypes) => {
  const [isOpen, setIsOpen] = useState(true);
  const iconSize = '19px';
  const checkedMap: boolean[] = [];

  const isSelected = (x) =>
    type === 'radio' ?
      x === selected :
      _.isArray(selected) ?
      selected.includes(x) :
      false;

  // todo: there must be a better way to do it
  if (type !== 'radio') {
    for (let i = 0; i < items.length; i++) {
      checkedMap[i] = isSelected(value(items[i], i));
    }
  }

  const handleChange = (index) => (e) => {
    if (type === 'radio') {
      onChange(value(items[index], index));
      return;
    }
    checkedMap[index] = e.target.checked;
    onChange(
        checkedMap.reduce((a: any[], x, i) => {
          if (x === true) {
            a.push(value(items[i], i));
          }
          return a;
        }, []),
    );
  };

  return (
    <div className="filter-section">
      <div
        className="filter-toggler"
        role="button"
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <H5 style="p1-b">{label}</H5>
        {isOpen ? (
          <Minus width={iconSize} height="100%" />
        ) : (
          <Plus width={iconSize} height="100%" />
        )}
      </div>
      <div className={'filter-content' + (isOpen ? ' show' : '')}>
        {items.map((x, i) => (
          <div className={`form-check form-check-${type}`} key={id(x)}>
            <input
              className="form-check-input"
              type={type}
              name={type === 'radio' ? label : undefined}
              id={id(x)}
              value={value(x, i)}
              onChange={handleChange(i)}
              checked={isSelected(value(x, i))}
            />
            <label className="form-check-label" htmlFor={id(x)}>
              {name(x)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

interface propTypes {
  items: {}[];
  selected: any | any[];
  type: 'radio' | 'checkbox';
  label: string;
  id?: (item) => string;
  name?: (item) => string;
  value?: (item, index?) => any;
  onChange: (item) => any;
}

export default FilterSection;
