import React from 'react';

const Subnav = ({artistSlug, active, hasSale = false, hasReviews = false, hasMeAtWork = false}) => {
  return (
    <nav className="subnav">
      <div className="container">
        <ul className="nav">
          <li className={`nav-item` + (active === 'artworks' ? ' active' : '')}>
            <a className="nav-link" href={`/artist/${artistSlug}`} title="View all artworks by">
              Artworks
            </a>
          </li>
          {hasMeAtWork && (
            <li className="nav-item">
              <a
                className="nav-link"
                href={`/artist/${artistSlug}/me-at-work/`}
                title="View photos of in their studio"
              >
                Me at work
              </a>
            </li>
          )}
          <li className="nav-item">
            <a className="nav-link" href={`/artist/${artistSlug}/about/`} title="About  Painter">
              About
            </a>
          </li>
          {hasReviews && (
            <li className="nav-item">
              <a className="nav-link" href={`/artist/${artistSlug}/reviews/`} title="Reviews of">
                Reviews
              </a>
            </li>
          )}
          {hasSale && (
            <li className={`nav-item` + (active === 'sale' ? ' active' : '')}>
              <a
                className="nav-link"
                href={`/artist/${artistSlug}/in_sale-true`}
                title="Artworks in sale from"
              >
                Sale
              </a>
            </li>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default Subnav;
