import React from 'react';
import Image from 'next/image';

const LazyImage = ({
  imageSrc,
  alt,
  mainColor = 'ebe8e5',
  width,
  height,
}: propTypes) => {
  const ratio = () => (100 * height) / width;

  return (
    <figure
      className="lazy-image"
      style={{paddingTop: `${ratio()}%`, backgroundColor: `#${mainColor}`}}
    >
      <Image
        alt={alt}
        src={imageSrc}
        layout="fill"
        objectFit="cover"
        unoptimized={true}
      />
    </figure>
  );
};

interface propTypes {
  imageSrc: string;
  alt: string;
  width: number;
  height: number;
  mainColor?: string;
}

export default LazyImage;
