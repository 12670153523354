import React, {useEffect, useRef} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import useMounted from '../../hooks/use-mounted/use-mounted';

let gap = 0;
let items: any = [];
let columns = 0;

const MasonryGallery = ({children}) => {
  const gallery: any = useRef(null);
  const masonrySupported = useRef(false);
  const hasMounted = useMounted();

  const setLayout = () => {
    if (!gallery.current) return;

    columns = getComputedStyle(gallery.current)?.gridTemplateColumns?.split(' ')?.length ?? 0;

    /* revert to initial positioning, no margin */
    items.forEach((el) => el.style.removeProperty('margin-top'));

    if (columns > 1) {
      /* Do not touch first row */
      items.slice(columns).forEach((el, i) => {
        const prevPos = items[i].getBoundingClientRect().bottom;
        const currPos = el.getBoundingClientRect().top;

        el.style.marginTop = `${prevPos + gap - currPos}px`;
      });
    }
  };

  useEffect(() => {
    let resizeObserver: any = null;

    if (getComputedStyle(gallery.current).gridTemplateRows !== 'masonry') {
      gap = parseFloat(getComputedStyle(gallery.current).rowGap);
      resizeObserver = new ResizeObserver((entries) => {
        // https://stackoverflow.com/a/58701523
        // We wrap it in requestAnimationFrame to avoid this error - Resize loop limit exceeded
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          // code to execute
          return setLayout();
        });
      });

      resizeObserver.observe(gallery.current);
    } else {
      masonrySupported.current = true;
    }

    return () => {
      if (resizeObserver) resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!hasMounted || masonrySupported.current) return;
    items = [...gallery.current.childNodes].filter(
      // eslint-disable-next-line max-len
      (el) => el.nodeType === 1 && +getComputedStyle(el).gridColumnEnd !== -1,
    );
    setLayout();
  }, [children]);

  return (
    <section
      ref={gallery}
      className={`masonry-gallery${!masonrySupported.current ? ' not-supported' : ''}`}
    >
      {children}
    </section>
  );
};

export default MasonryGallery;
